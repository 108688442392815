/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"
import { Box } from "@theme-ui/components"

type TitleProps = {
  children?: React.ReactNode
  as?: string
  className?: string
  text: string
}

const Title = ({ text, children, as = `h2`, className }: TitleProps) => (
  <Box sx={{ padding: `0 60px 0 60px`, width: `90vw`, mb: 3, }}>
    <Box
      as={as}
      sx={{ fontWeight: `800`, fontSize: `5rem`, fontFamily: `Montserrat`, lineHeight: `6rem`, color: `#212121`, letterSpacing: `2.4px`, textTransform: `Uppercase`, }}
      className={className}
    >
      {text}
    </Box>
    <Box
      sx={{
        color: `secondary`,
        a: {
          variant: `links.secondary`,
        },
      }}
    >
      {children}
    </Box>
  </Box>
)

export default Title
